import React, { useEffect, useState } from 'react';
import AdminComponent from '../../admin/admin-component';
import ForceAuthentication from './force-authentication';
import { useSession } from 'next-auth/react';
import { authOptions } from '../../api/auth/[...nextauth]';
import fetchUser from '../../../app/api/useSsoAuthenticate';

function AdminLayout(props: { children: any }) {
    const {
        data: session,
        status,
        update: updateSession,
    } = useSession(authOptions);
    const [newSession, setNewSession] = useState<any>(null);
    useEffect(() => {
        const AuthenticateUser = async () => {
            if (session && !(newSession && newSession.user)) {
                try {
                    const { extendedSession } = await fetchUser(
                        session,
                        authOptions
                    );
                    setNewSession(extendedSession);
                } catch (error) {
                    console.error('Authentication failed:', error);
                }
            }
        };
        AuthenticateUser();
    }, [session]);

    if (status === 'unauthenticated') {
        return <ForceAuthentication />;
    }
    if (status === 'loading') {
        return <></>;
    }
    return (
        <>
            <AdminComponent session={newSession}>
                {props.children}
            </AdminComponent>
        </>
    );
}

export default AdminLayout;
