import { Configuration, generateAuthConfiguration } from '@storable/next-okta';

const config: Configuration = {
    okta: {
        clientId: process.env.OKTA_CLIENT_ID ?? '',
        clientSecret: process.env.OKTA_CLIENT_SECRET ?? '',
        orgDomain: process.env.OKTA_ORG_DOMAIN ?? '',
        publicUrl: process.env.NEXTAUTH_URL ?? '',
    },
    session: {
        strategy: 'jwt',
        // 8 hours for maxAge
        maxAge: 8 * 60 * 60,
    },
};

// Generate authentication options and nextAuth instance
export const { authOptions, nextAuth } = generateAuthConfiguration(config);
export default nextAuth;
