const fetchUser = async (session: any, authOptions: any) => {
    const extendedSession = session;
    const accessToken = extendedSession?.accessToken;

    const fetchUserDetails = async () => {
        try {
            if (accessToken) {
                const response = await fetch('/api/sso/sso-authenticate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: accessToken }),
                });

                if (!response.ok) {
                    throw new Error('Failed to authenticate with SSO');
                }
                if (extendedSession) {
                    extendedSession.user = await response.json();
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    await fetchUserDetails();
    return { extendedSession };
};

export default fetchUser;
