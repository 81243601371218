'use client';

import { signIn } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import React from 'react';
import { useEffect } from 'react';

const ForceAuthentication = () => {
    const pathname = usePathname();

    useEffect(() => {
        signIn('okta', { callbackUrl: pathname ?? undefined });
    }, [pathname]);

    return <></>;
};

export default ForceAuthentication;
