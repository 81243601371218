import {
    Navbar,
    Container,
    NavbarBrand,
    NavbarToggler,
    NavbarCollapse,
    NavbarNav,
    NavItem,
    NavLink,
} from '@storable/react-library';

import '@storable/react-library/styles.css';

import logo from 'public/Payments_logo.png';
import Image from 'next/image';
import styles from './SiteHeader.module.scss';
import { useRouter } from 'next/router';

export default function MainBar() {
    const router = useRouter();
    const isActive = (path: string) => {
        return router.pathname.startsWith(path);
    };

    return (
        <>
            <Navbar background="dark" className={`${styles.mainNav}`} expand>
                <Container fluid>
                    <NavbarBrand className={`${styles.logo}`}>
                        <Image src={logo} alt="" />
                    </NavbarBrand>
                    <NavbarToggler />
                    <NavbarCollapse>
                        <NavbarNav>
                            <NavItem>
                                <NavLink
                                    href="/transactions"
                                    active={isActive('/transactions')}
                                >
                                    Transactions
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/bank_activity"
                                    active={
                                        isActive('/bank_activity') ||
                                        isActive('/deposit_details')
                                    }
                                >
                                    Bank activity
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/chargebacks"
                                    active={isActive('/chargebacks')}
                                >
                                    Chargebacks
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/merchant_accounts"
                                    active={isActive('/merchant_accounts')}
                                >
                                    Merchant accounts
                                </NavLink>
                            </NavItem>
                        </NavbarNav>
                    </NavbarCollapse>
                </Container>
            </Navbar>
        </>
    );
}
