import Header from '../header';
import styles from '../../styles/main.module.css';
import { useEffect, useState } from 'react';
import { isWindowWithinIFrame } from '../../../app/utilities/mfe-helpers';
import { initializeMFEProvider } from '../../../app/providers/mfe.provider';

function Layout(props: { children: any }) {
    const [showNavBar, setShowNavBar] = useState(false);
    useEffect(() => {
        // page has loaded and is within an iframe
        if (isWindowWithinIFrame()) {
            console.debug(
                'Application has detected that it is within an iframe'
            );
            setupMFEProvider();
        } else {
            setShowNavBar(true);
        }
    }, []);

    const setupMFEProvider = async () => {
        console.debug('Initializing MFE Provider');
        await initializeMFEProvider();
        console.debug('MFE Provider initialized');
    };

    return (
        <>
            <Header showNavBar={showNavBar} />
            <div className={styles.mainContainer}>{props.children}</div>
        </>
    );
}

export default Layout;
