import styles from './admin-bar.module.scss';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavbarCollapse,
    NavbarNav,
    NavItem,
    NavLink,
    Container,
    Badge,
    Row,
    Col,
} from '@storable/react-library';
import logo from 'public/payments-internal-logo.png';
import Image from 'next/image';
import React from 'react';

export default function AdminBar(props: any) {
    return (
        <>
            <Navbar className={styles.adminNav} expand>
                <Container fluid>
                    <NavbarBrand>
                        <Image src={logo} alt="payments-internal" />
                    </NavbarBrand>
                    <NavbarToggler />
                    <NavbarCollapse>
                        <NavbarNav>
                            <NavItem>
                                <NavLink
                                    className={styles.adminNavLink}
                                    href="facility_status"
                                >
                                    Locations
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={styles.adminNavLink}
                                    href="bulk_import"
                                >
                                    Bulk Import
                                </NavLink>
                            </NavItem>
                        </NavbarNav>
                    </NavbarCollapse>
                    <Row>
                        <Col>
                            <Badge className={styles.adminNavLink} pill>
                                {props.session?.user?.firstName
                                    ?.charAt(0)
                                    .toUpperCase()}
                            </Badge>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </>
    );
}
