import React from 'react';
import { Spinner } from '@storable/react-library';
import AdminHeader from '../shared_components/admin-header';
import styles from '../styles/main.module.css';
import { useSession } from 'next-auth/react';

export default function AdminComponent({
    session,
    children,
}: {
    children: React.ReactNode;
    session: any;
}) {
    const { status } = useSession();
    if (session && status === 'authenticated') {
        return (
            <>
                <AdminHeader session={session}></AdminHeader>
                <div className={styles.mainContainer}>{children}</div>
            </>
        );
    } else {
        return (
            <>
                <Spinner
                    aria-label="loading spinner"
                    animation="border"
                    size="xl"
                />
                Loading...
            </>
        );
    }
}
