import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Layout from './shared_components/layout';
import AdminLayout from './shared_components/layout/admin-layout';
import { SessionProvider } from 'next-auth/react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const FallbackComponent: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    return <div>{children}</div>;
};

let BugsnagClient: any = null;

const BugsnagErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const ErrorBoundary =
        BugsnagClient?.getPlugin('react')?.createErrorBoundary(React) ||
        FallbackComponent;

    return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default function _App({ Component, pageProps, ...appProps }: AppProps) {
    const [isClient, setIsClient] = useState(false);
    const [bugsnagApiKey, setBugsnagApiKey] = useState<string | null>(null);
    const [bugsnagReleaseStage, setBugsnagReleaseStage] = useState<
        string | null
    >(null);

    useEffect(() => {
        const fetchBugsnagKey = async () => {
            try {
                const res = await fetch('/api/bugsnag-key');
                const data = await res.json();
                setBugsnagApiKey(data.bugsnagApiKey);
                setBugsnagReleaseStage(data.bugsnagReleaseStage);
            } catch (error) {
                console.error('Error fetching Bugsnag API Key:', error);
            }
        };

        if (!BugsnagClient && typeof window !== 'undefined') {
            fetchBugsnagKey();
        }
    }, []);

    useEffect(() => {
        if (bugsnagApiKey && !BugsnagClient) {
            BugsnagClient = Bugsnag.start({
                apiKey: bugsnagApiKey,
                plugins: [new BugsnagPluginReact()],
                releaseStage: bugsnagReleaseStage || 'development',
            });
        }
        setIsClient(true);
    }, [bugsnagApiKey, bugsnagReleaseStage]);

    const handlerRouterPath = () => {
        const ComponentToRender = <Component {...pageProps} />;

        if (appProps.router.pathname.startsWith('/admin/')) {
            return (
                <AdminLayout>
                    {isClient ? (
                        <BugsnagErrorBoundary>
                            {ComponentToRender}
                        </BugsnagErrorBoundary>
                    ) : (
                        ComponentToRender
                    )}
                </AdminLayout>
            );
        } else if (appProps.router.pathname === '/bank_activity') {
            return <Layout>{ComponentToRender}</Layout>;
        } else {
            return (
                <Layout>
                    {isClient ? (
                        <BugsnagErrorBoundary>
                            {ComponentToRender}
                        </BugsnagErrorBoundary>
                    ) : (
                        ComponentToRender
                    )}
                </Layout>
            );
        }
    };

    return (
        <SessionProvider session={pageProps.session}>
            {handlerRouterPath()}
        </SessionProvider>
    );
}
